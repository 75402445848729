<template>
  <div>
    <div v-show="editable&&!disabled" class="internalActionDiv">
      <q-btn-group outline >
        <!--평가완료-->
        <c-btn 
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="targetDept"
          mappingType="PUT"
          label="LBL0010136" 
          icon="check"
          @beforeAction="completeAction"
          @btnCallback="completeActionCallback" />
      </q-btn-group>
    </div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :targetDept.sync="targetDept"
          :imprTabInfo.sync="imprTabInfo"
          :disabled.sync="disabled"
          :contentHeight="contentHeight"
          @getDetail="getDetail"
          @stepup="stepup"
          @setRegInfo="setRegInfo"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'internal-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalActionTargetDeptId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'checklist',
      tabItems: [
        { name: 'checklist', icon: 'info', label: 'LBL0010137', component: () => import(`${'./internalResultChecklist.vue'}`) },  //심사(체크리스트)
        { name: 'improvement', icon: 'info', label: 'LBL0010138', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`) },   //감소대책 및 시정조치
        { name: 'result', icon: 'info', label: 'LBL0010139', component: () => import(`${'./internalResultAttach.vue'}`) },    //결과
      ],
      targetDept: {
        saiInternalActionTargetDeptId: '',  // 내부심사 피심사팀 일련번호
        saiInternalActionId: '',  // 내부심사 수행계획서 일련번호
        actionName: '',
        deptCd: '',  // 피심사부서코드
        deptName: '',  // 피심사부서코드
        saiInternalActionTargetDeptStepCd: '',  // 내부심사 피심사팀 진행상태
        resultRemark: '',  // 결과요약
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklist: [], // 체크리스트 결과
        auditTeams: [], // 심사팀
      },
      editable: true,
      isComplete: false,
      detailUrl: '',
      stepUpdateUrl: '',
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let _margin = 36;
      if (!this.disabled) {
        _margin += 40
      }
      return String(this.contentHeight - _margin) + 'px';
    },
    disabled() {
      return this.targetDept.saiInternalActionTargetDeptStepCd === 'IATD000010';
    },
    imprTabInfo() {
      return {
        key: this.popupParam.saiInternalActionTargetDeptId, // 
        ibmTaskTypeCd: 'ITT0000150',
        ibmTaskUnderTypeCd: 'ITTU000150',
        requestContents: this.targetDept.actionName + ' / ' + this.targetDept.deptName,
        disabled: this.disabled,
        isOld: true,
        gridItem: {
          listUrl: '',
          param: null,
          //체크리스트 별 개선사항 목록
          title: 'LBL0010140',
          merge: [
            { index: 0, colName: 'saiInternalAuditClassName' },
            { index: 1, colName: 'saiInternalAuditClassNameGubun' },
          ],
          data: this.$_.filter(this.targetDept.checklist, (item) => {
            return Boolean(item.sopImprovementIds)
          }),
          columns: [
            {
              name: 'saiInternalAuditClassName',
              field: 'saiInternalAuditClassName',
              // ISO 구분
              label: 'ISO 구분',
              align: 'center',
              style: 'width:80px',
              sortable: false,
            },
            {
              name: 'systemElementsName',
              field: 'systemElementsName',
              //시스템요소
              label: 'LBL0010128',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'sortOrder',
              field: 'sortOrder',
              //순서(순번)
              label: 'LBLSORTORDER',
              align: 'center',
              style: 'width:30px',
              sortable: false,
            },
            {
              name: 'itemName',
              field: 'itemName',
              //내부심사 항목
              label: 'LBL0010129',
              align: 'left',
              style: 'width:450px',
              sortable: false,
              type: 'html',
            },
            {
              name: 'aimMatter',
              field: 'aimMatter',
              //내부심사 시<br/>착안사항
              label: 'LBL0010130',
              align: 'left',
              style: 'width:200px',
              sortable: false,
              type: 'html',
            },
            {
              name: 'result',
              field: 'result',
              //내부심사결과
              label: '',
              child: [
                {
                  name: 'sar0000001',
                  field: 'sar0000001',
                  //양호
                  label: 'LBL0010142',
                  align: 'center',
                  style: 'width:40px',
                  sortable: false,
                  type: 'check',
                  checkIcon: 'task_alt',
                  uncheckIcon: 'radio_button_unchecked',
                  'true': 'Y',
                  'false': 'N',
                  color: 'green', 
                },
                {
                  name: 'sar0000005',
                  field: 'sar0000005',
                  //부적합
                  label: 'LBL0010143',
                  align: 'center',
                  style: 'width:40px',
                  sortable: false,
                  type: 'check',
                  checkIcon: 'task_alt',
                  uncheckIcon: 'radio_button_unchecked',
                  'true': 'Y',
                  'false': 'N',
                  color: 'green', 
                },
                {
                  name: 'sar0000010',
                  field: 'sar0000010',
                  //관찰사항
                  label: 'LBL0010144',
                  align: 'center',
                  style: 'width:40px',
                  sortable: false,
                  type: 'check',
                  checkIcon: 'task_alt',
                  uncheckIcon: 'radio_button_unchecked',
                  'true': 'Y',
                  'false': 'N',
                  color: 'green', 
                },
                {
                  name: 'sar0000015',
                  field: 'sar0000015',
                  //비적용
                  label: 'LBL0010145',
                  align: 'center',
                  style: 'width:40px',
                  sortable: false,
                  type: 'check',
                  checkIcon: 'task_alt',
                  uncheckIcon: 'radio_button_unchecked',
                  'true': 'Y',
                  'false': 'N',
                  color: 'green', 
                },
              ]
            },
            {
              name: 'observation',
              field: 'observation',
              //관찰내용<br/>(부적합사항<br/>및 보고서No.)
              label: 'LBL0010146',
              align: 'left',
              style: 'width:250px',
              sortable: false,
              type: 'textarea',
            },
          ],
          research: this.research,
        },
        gridOther: {
          //항목외 개선 목록
          title: 'LBL0010147',
        },
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.internal.targetDept.get.url;
      this.stepUpdateUrl = transactionConfig.sai.internal.targetDept.action.url;
      this.completeUrl = transactionConfig.sai.internal.targetDept.complete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiInternalActionTargetDeptId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiInternalActionTargetDeptId);
        this.$http.type = 'GET';
        this.$http.param = {
          isResult: true,
        };
        this.$http.request((_result) => {
          this.$_.extend(this.targetDept, _result.data)
        },);
      }
    },
    stepup() {
      /**
       * when : 한번이라도 저장시
       * action : 준비중 단계인 경우 심사중으로 변경
       */
      if (this.targetDept.saiInternalActionTargetDeptStepCd === 'IATD000001') {
        this.$http.url = this.stepUpdateUrl;
        this.$http.type = 'PUT';
        this.$http.param = {
          saiInternalActionTargetDeptId: this.targetDept.saiInternalActionTargetDeptId
        }
        this.$http.request((_result) => {
          this.$set(this.targetDept, 'saiInternalActionTargetDeptStepCd', _result.data)
        },);
      }
    },
    completeAction() {
      /**
       * 현재는 유효성 넣지 않음
       */
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',    // 확인
        message: 'MSGCOMPLETE', // 완료하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.targetDept.chgUserId = this.$store.getters.user.userId

          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeActionCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
    },
    setRegInfo(data) {
      this.$emit('setRegInfo', data)
    }
  }
};
</script>
<style lang="sass">
.internalActionDiv
  width:100%
  height: 30px
  .q-btn-group
    position: absolute
    right: 0px
    padding-right: 10px
</style>